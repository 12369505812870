import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { TeamMemberPage, TeamMemberPageProps } from '../components/team/TeamMemberPage'
import { withIsDispensaryPageContext } from '../hoc/withIsDispensaryPageContext'

export type TeamMemberTemplateProps = TeamMemberPageProps

const TeamMemberTemplate: FC<TeamMemberTemplateProps> = ({ data, pageContext }) => {
  return <TeamMemberPage data={data} pageContext={pageContext} />
}

// Query for team member data
export const query = graphql`
  query TeamMemberQuery($id: String!) {
    teamMember: sanityTeamMemberLeadership(id: { eq: $id }) {
      ...sanityTeamMember
    }
  }
`

export default withIsDispensaryPageContext(TeamMemberTemplate)
