import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  TeamMemberButtonNavigation,
} from '@dfh-corp-sites/ui'
import { BlockContentProps } from '@sanity/block-content-to-react'
import { graphql, PageProps } from 'gatsby'
import React, { FC, useEffect, useRef } from 'react'

import Layout from '../../components/global/Layout'
import Link from '../../components/global/Link'
import { SanityImage } from '../../graphql/gatsby'
import Head from '../../layouts/Head'
import { useDispensaryContext } from '../../lib/dispensaries'
import { TeamMemberContext } from '../../lib/pages/types'
import { getGatsbyImageDataForAsset } from '../../utils/sanity/image'
import BodyPortableText from '../sanity-page/BodyPortableText'

type TeamMemberQueryData = {
  teamMember: {
    id: string
    name: string
    title?: string
    biography?: BlockContentProps['blocks']
    photo?: SanityImage
    slug?: {
      current: string
    }
  }
}

export type TeamMemberPageProps = Pick<
  PageProps<TeamMemberQueryData, TeamMemberContext>,
  'data' | 'pageContext'
>

export const TeamMemberPage: FC<TeamMemberPageProps> = ({ data, pageContext }) => {
  const { teamMember } = data
  const { prevName, prevTitle, prevSlug, nextName, nextTitle, nextSlug } = pageContext
  const dispensaryContext = useDispensaryContext()
  const dispensarySlug = dispensaryContext?.slug || ''

  const image = teamMember.photo
    ? getGatsbyImageDataForAsset(teamMember.photo, {
        placeholder: 'blurred',
      })
    : null

  const title = `${teamMember.name} - ${teamMember.title || 'Leadership'} | Designs for Health`
  const description = teamMember.biography
    ? `Learn more about ${teamMember.name}, ${teamMember.title || 'Leadership'} at Designs for Health.`
    : 'Our leadership team at Designs for Health is committed to providing high-quality nutritional products and education.'

  // Helper function to generate links based on dispensary context
  const getPath = (path: string) => {
    const isDispensary = !!dispensaryContext
    return isDispensary ? `/u/${dispensarySlug}${path}` : path
  }

  const bioHeaderRef = useRef<HTMLDivElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateImagePosition = () => {
      if (bioHeaderRef.current && imageContainerRef.current) {
        const height = bioHeaderRef.current.offsetHeight
        imageContainerRef.current.style.marginTop = `${-height}px`
      }
    }
    updateImagePosition()
    window.addEventListener('resize', updateImagePosition)

    return () => {
      window.removeEventListener('resize', updateImagePosition)
    }
  }, [])
  return (
    <>
      <Head title={title} description={description} image={teamMember.photo} />
      <Layout>
        <main id="main-content" tabIndex={-1} className="outline-none grid">
          <div className="w-full border-b border-b-[#CFCECE] grid md:block overflow-clip h-full">
            <div className="w-full">
              <div
                ref={bioHeaderRef}
                title={`${teamMember.name} - ${teamMember.title || ''}`}
                className="flex flex-col gap-3 md:gap-3.5 lg:pl-10 xl:pb-10 lg:pt-6 border-b border-b-[#CFCECE] px-2.5 pb-[30px] lg:h-auto pt-5 md:pl-5"
              >
                <Breadcrumb aria-label="Breadcrumb" className="[&_li]:text-base">
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink asChild>
                        <Link to={getPath('/our-story/leadership')}>Leadership</Link>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbPage>Our Leadership</BreadcrumbPage>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>

                <h1
                  id="team-member-heading"
                  className="xl:text-6xl md:text-[50px] text-[40px] text-brand-navy font-semibold max-w-80 leading-none md:line-clamp-2 lg:line-clamp-none"
                >
                  {teamMember.name}
                </h1>
                {teamMember.title && (
                  <h2 className="md:text-2xl text-xl text-brand-navy-2">{teamMember.title}</h2>
                )}
              </div>

              <div
                ref={imageContainerRef}
                className="w-1/2 float-right relative hidden md:block ml-5"
                aria-hidden="true"
              >
                {image && image.images && image.images.fallback && (
                  <img
                    src={image.images.fallback.src}
                    alt=""
                    className="w-full h-full object-cover"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="lg:pt-10 lg:pl-20 lg:pr-10 md:px-2.5 md:pt-5 pb-10">
                <div className="w-full relative block md:hidden -mr-2.5">
                  {image && image.images && image.images.fallback && (
                    <img
                      src={image.images.fallback.src}
                      alt={`Portrait of ${teamMember.name}, ${teamMember.title || 'Team Member'}`}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                {teamMember.biography && (
                  <section className="px-2.5 text-balance" aria-labelledby="team-member-heading">
                    <BodyPortableText className="[&_p]:text-base" blocks={teamMember.biography} />
                  </section>
                )}
              </div>
            </div>
          </div>

          <nav
            aria-label="Team member navigation"
            className="grid md:grid-cols-2 grid-cols-1  w-full min-h-80 md:min-h-40 lg:min-h-52"
          >
            {prevSlug ? (
              <TeamMemberButtonNavigation
                asChild
                direction="previous"
                name={prevName || ''}
                title={prevTitle}
              >
                <Link
                  to={getPath(`/our-story/leadership/${prevSlug}`)}
                  aria-label={`Previous team member: ${prevName}${prevTitle ? `, ${prevTitle}` : ''}`}
                />
              </TeamMemberButtonNavigation>
            ) : (
              <div className="py-[60px] px-10" aria-hidden="true" />
            )}

            {nextSlug ? (
              <TeamMemberButtonNavigation
                asChild
                direction="next"
                name={nextName || ''}
                title={nextTitle}
              >
                <Link
                  to={getPath(`/our-story/leadership/${nextSlug}`)}
                  aria-label={`Next team member: ${nextName}${nextTitle ? `, ${nextTitle}` : ''}`}
                />
              </TeamMemberButtonNavigation>
            ) : (
              <div className="py-[60px] px-10" aria-hidden="true" />
            )}
          </nav>
        </main>
      </Layout>
    </>
  )
}

export const teamMemberFragment = graphql`
  fragment sanityTeamMember on SanityTeamMemberLeadership {
    id
    name
    title
    biography: _rawBiography
    photo {
      asset {
        _id
      }
      hotspot {
        height
        width
        x
        y
      }
      crop {
        bottom
        left
        right
        top
      }
    }
    slug {
      current
    }
  }
`
